<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints, useEventListener } from '@vueuse/core'

import * as THREE from 'three'

const root = ref<HTMLElement | null>(null)
const variation = ref(2)

const scene = new THREE.Scene()
const vMouse = new THREE.Vector2()
const vMouseDamp = new THREE.Vector2()
const vResolution = new THREE.Vector2()

const width = ref(0)
const height = ref(0)

const aspect = width.value / height.value
const camera = new THREE.OrthographicCamera(-aspect, aspect, 1, -1, 0.1, 1000)

const renderer = new THREE.WebGLRenderer({
  alpha: true,
})

// Plane geometry covering the full viewport
const geo = new THREE.PlaneGeometry(1, 1) // Scaled to cover full viewport

// Shader material creation
const mat = new THREE.ShaderMaterial({
  vertexShader: /* glsl */`
    varying vec2 v_texcoord;
    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        v_texcoord = uv;
    }`,
  fragmentShader: `
  varying vec2 v_texcoord;

uniform vec2 u_mouse;
uniform vec2 u_resolution;
uniform float u_pixelRatio;

/* common constants */
#ifndef PI
#define PI 3.1415926535897932384626433832795
#endif
#ifndef TWO_PI
#define TWO_PI 6.2831853071795864769252867665590
#endif

/* variation constant */
#ifndef VAR
#define VAR 0
#endif

/* Coordinate and unit utils */
#ifndef FNC_COORD
#define FNC_COORD
vec2 coord(in vec2 p) {
    p = p / u_resolution.xy;
    // correct aspect ratio
    if (u_resolution.x > u_resolution.y) {
        p.x *= u_resolution.x / u_resolution.y;
        p.x += (u_resolution.y - u_resolution.x) / u_resolution.y / 2.0;
    } else {
        p.y *= u_resolution.y / u_resolution.x;
        p.y += (u_resolution.x - u_resolution.y) / u_resolution.x / 2.0;
    }
    // centering
    p -= 0.5;
    p *= vec2(-1.0, 1.0);
    return p;
}
#endif

#define st0 coord(gl_FragCoord.xy)
#define mx coord(u_mouse * u_pixelRatio)

/* signed distance functions */
float sdRoundRect(vec2 p, vec2 b, float r) {
    vec2 d = abs(p - 0.5) * 4.2 - b + vec2(r);
    return min(max(d.x, d.y), 0.0) + length(max(d, 0.0)) - r;
}
float sdCircle(in vec2 st, in vec2 center) {
    return length(st - center) * 2.0;
}
float sdPoly(in vec2 p, in float w, in int sides) {
    float a = atan(p.x, p.y) + PI;
    float r = TWO_PI / float(sides);
    float d = cos(floor(0.5 + a / r) * r - a) * length(max(abs(p) * 1.0, 0.0));
    return d * 2.0 - w;
}

/* antialiased step function */
float aastep(float threshold, float value) {
    float afwidth = length(vec2(dFdx(value), dFdy(value))) * 0.70710678118654757;
    return smoothstep(threshold - afwidth, threshold + afwidth, value);
}
/* Signed distance drawing methods */
float fill(in float x) { return 1.0 - aastep(0.0, x); }
float fill(float x, float size, float edge) {
    return 1.0 - smoothstep(size - edge, size + edge, x);
}

float stroke(in float d, in float t) { return (1.0 - aastep(t, abs(d))); }
float stroke(float x, float size, float w, float edge) {
    float d = smoothstep(size - edge, size + edge, x + w * 0.5) - smoothstep(size - edge, size + edge, x - w * 0.5);
    return clamp(d, 0.0, 1.0);
}

void main() {
    vec2 pixel = 1.0 / u_resolution.xy;
    vec2 st = st0 + 0.5;
    vec2 posMouse = mx * vec2(1., -1.) + 0.5;
    
    /* sdf (Round Rect) params */
    float size = 1.2;
    float roundness = 0.4;
    float borderSize = 0.05;
    
    /* sdf Circle params */
    float circleSize = 0.5;
    float circleEdge = 0.8;
    
    /* sdf Circle */
    float sdfCircle = fill(
        sdCircle(st, posMouse),
        circleSize,
        circleEdge
    );
    
    float sdf;
    float sdf2;
    if (VAR == 0) {
        /* sdf round rectangle with stroke param adjusted by sdf circle */
        sdf = sdRoundRect(st, vec2(size), roundness);
        sdf = stroke(sdf, 0.0, borderSize, sdfCircle) * 4.0;
    } else if (VAR == 1) {
        /* sdf circle with fill param adjusted by sdf circle */
        sdf = sdCircle(st, vec2(0.5));
        sdf = fill(sdf, 0.6, sdfCircle) * 1.2;
    } else if (VAR == 2) {
        /* sdf circle with stroke param adjusted by sdf circle */
        sdf = sdCircle(st, vec2(0.5));
        sdf = stroke(sdf, 0.58, 0.005, sdfCircle) * 4.0;
        sdf2 = sdCircle(st, vec2(0.5));
        sdf2 = stroke(sdf2, 0.65, 0.01, sdfCircle) * 4.0;
    } else if (VAR == 3) {
        /* sdf circle with fill param adjusted by sdf circle */
        sdf = sdPoly(st - vec2(0.5, 0.45), 0.3, 3);
        sdf = fill(sdf, 0.05, sdfCircle) * 1.4;
    }
    
    vec3 color = vec3(sdf);
    color += vec3(sdf2 * vec3(0.5, 0.5, 0.0));

    gl_FragColor = vec4(color.rgb, 0.01);

}`, // most of the action happening in the fragment
  uniforms: {
    u_mouse: { value: vMouseDamp },
    u_resolution: { value: vResolution },
    u_pixelRatio: { value: 2 },
  },
  defines: {
    VAR: variation.value,
  },
})

// Mesh creation
const quad = new THREE.Mesh(geo, mat)

function init() {
  if (!root.value)
    return

  width.value = root.value.clientWidth
  height.value = root.value.clientHeight

  root.value.appendChild(renderer.domElement)

  // Point the blur to the bottom left corner
  vMouse.set(window.innerWidth / 2, window.innerHeight)

  scene.add(quad)

  camera.position.z = 1 // Set appropriately for orthographic

  renderer.setSize(width.value, height.value)
}

function onPointerMove(e: MouseEvent) {
  vMouse.set(e.pageX, e.pageY)
}

let time
let lastTime = 0
function animate() {
  // calculate delta time
  time = performance.now() * 0.001
  const dt = time - lastTime
  lastTime = time

  // ease mouse motion with damping
  for (const k in vMouse) {
    if (k === 'x' || k === 'y')
      vMouseDamp[k] = THREE.MathUtils.damp(vMouseDamp[k], vMouse[k], 8, dt)
  }

  // render scene
  requestAnimationFrame(animate)
  renderer.render(scene, camera)
}

function resize() {
  if (!root.value || !window)
    return

  width.value = root.value.clientWidth
  height.value = root.value.clientHeight

  const dpr = Math.min(window.devicePixelRatio, 2)

  renderer.setSize(width.value, height.value)
  renderer.setPixelRatio(dpr)

  camera.left = -width.value / 2
  camera.right = width.value / 2
  camera.top = height.value / 2
  camera.bottom = -height.value / 2
  camera.updateProjectionMatrix()

  quad.scale.set(width.value, height.value, 1)
  vResolution.set(width.value, height.value).multiplyScalar(dpr)
  mat.uniforms.u_pixelRatio.value = dpr
}

const breakpoints = useBreakpoints(breakpointsTailwind)

onMounted(() => {
  init()
  resize()
  animate()

  if (breakpoints.lg.value === true) {
    useEventListener(root.value, 'mousemove', onPointerMove)
    useEventListener(root.value, 'pointermove', onPointerMove)
  }
})

onBeforeUnmount(() => {
  scene.remove(quad)
  geo.dispose()
  mat.dispose()
})

useEventListener(window, 'window', resize)
</script>

<template>
  <div ref="root" class="flex justify-center items-center" />
</template>
